import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Iframe from "react-iframe";
import i18next from "i18next";
import title from "../../../../assets/full logo-fayda podcast-01.png";
import { useSelector } from "react-redux";
import { getGalleries } from "../../../../redux/selectors";
import play from "../../../../assets/play.png";
import play1 from "../../../../assets/play icon-01 copy.png";
import thumbnail from "../../../../assets/landingPageThumbnail.jpg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import idDayImage from "../../../../assets/podcast-idDay.jpg";
import idDayLogo from "../../../../assets/ID-Day-Logo.png";
import ReactPlayer from "react-player";
const IDHeroPodcast = () => {
    const one = useMediaQuery({
        query: "(max-height: 300px)",
    });

    const TwoFiveZeroPercent = useMediaQuery({
        query: "(max-height: 400px)",
    });

    const TwoZeroZeroPercent = useMediaQuery({
        query: "(max-height: 500px)",
    });

    const OneSevenFivePercent = useMediaQuery({
        query: "(max-height: 600px)",
    });

    const OneFivePercent = useMediaQuery({
        query: "(max-height: 700px)",
    });

    const OneTwoFivePercent = useMediaQuery({
        query: "(max-height: 800px)",
    });

    const OneOneTenPercent = useMediaQuery({
        query: "(max-height: 900px)",
    });

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const navigate = useNavigate();

    const { t } = useTranslation();

    // const list = useSelector(getGalleries);

    const [VidOpen, setVidOpen] = useState(false);
    const [vidModal, setVidModal] = useState(
        "https://www.youtube.com/watch?v=RDxhI4e2Iv0"
    );

    function toggleVidModal(vidUrl) {
        setVidOpen(!VidOpen);
        // setVidModal(vidUrl);
    }

    // const latestPodCast = list.filter((item) => item.type === "Podcast");
    const backgroundImage = `url(${idDayImage})`;

    return (
        <>
            {VidOpen && (
                <div
                    className='fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                    onClick={toggleVidModal}
                >
                    <div className='relative w-full h-full px-2 flex hover:cursor-pointer hover:scale-105 transition-all duration-500 items-center justify-center'>
                        {/* <Iframe
                            url={vidModal}
                            id=''
                            className='flex w-[80%] h-full'
                            display='block'
                            position='relative'
                            frameborder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            allowfullscreen
                        /> */}

                        <ReactPlayer url={vidModal} width='80%' height='100%' />
                    </div>
                </div>
            )}

            <div
                // class='bg-landing-podcast-hero flex w-full h-full bg-center bg-cover'
                className='w-full h-full bg-cover'
                style={{
                    backgroundImage: backgroundImage,
                }}
            >
                <div className='flex flex-col gap-6 xl:flex-row w-full h-full px-5 xl:px-28 pt-20 xl:pt-32 pb-12'>
                    <div className='flex flex-col h-full gap-4 w-full items-center xl:items-start'>
                        <div className='flex h-full w-[100%] xl:w-[50%] xl:-ml-[5%]'>
                            <img
                                src={idDayLogo}
                                alt=''
                                className='object-fill'
                            />
                        </div>
                        <div className=' text-[#083247] w-[80%] text-justify text-xl font-semibold'>
                            {t("podcast-translation")}
                        </div>
                        <div className=' text-[#083247] w-[80%] text-justify text-xl font-semibold'>
                            {t("podcast-translation-2")}
                        </div>
                        <a
                            href='https://www.id-day.org'
                            className='font-bold text-2xl w-fit border-2 border-white px-14 py-1 mt-5 rounded-md text-white bg-[#083247]/90 mb-2 hover:bg-[#083247] hover:scale-105 duration-300 ease-in-out'
                        >
                            {t("learn-more")}
                        </a>
                    </div>
                    <div className='flex flex-col min-h-full w-full justify-center'>
                        <div className='flex items-center w-full h-full justify-center'>
                            <div
                                className='max-w-[700px] flex relative w-full hover:scale-105 hover:cursor-pointer transition-all duration-500 ease-in-out'
                                onClick={() =>
                                    toggleVidModal(
                                        "https://www.youtube.com/watch?v=RDxhI4e2Iv0"
                                    )
                                }
                            >
                                <div className='flex justify-center w-full h-full rounded-xl aspect-video border-[12px] border-white/40 overflow-clip'>
                                    <img
                                        src={thumbnail}
                                        alt='video'
                                        className='bg-cover w-full'
                                    />
                                </div>
                                <div className='absolute z-40 inset-0 flex items-center justify-center'>
                                    <img
                                        src={play}
                                        alt='play img'
                                        className='object-cover scale-[0.15]'
                                    />
                                </div>
                                <div className='absolute z-30 bg-[#FFA42A]/20 w-full h-full inset-0'></div>
                            </div>
                        </div>
                        {/* <div
                                    className='flex w-full h-full justify-center items-end hover:cursor-pointer hover:scale-105 transition-all duration-500 ease-in-out group'
                                    onClick={() => navigate("/podcast")}
                                >
                                    <div className='rounded-full bg-gray-500 relative'>
                                        <div className='absolute w-[80px] h-[80px] top-1/2 left-1/2 transform -translate-x-[49px] -translate-y-[64px]'>
                                            <img
                                                src={play1}
                                                alt='play1 img'
                                                className='object-cover'
                                            />
                                        </div>
                                    </div>
                                    <div className='bg-green group-hover:bg-MetallicBlue hover:text-green transition-all duration-500 ease-in-out py-2 px-12 text-center text-2xl font-semibold text-white rounded-full'>
                                        <p>{t("podcast-button")}</p>
                                    </div>
                                </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};
export default IDHeroPodcast;
