import React, { useState } from "react";
import HeroLandingPage from "./components/hero/heroLandingPage";
import { useMediaQuery } from "react-responsive";
import Body from "./components/body/body";
import BodyNews from "./components/body/bodyMobile";
import SmallFooter from "../../components/footer/smallFooter";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

// import { Card } from "react-bootstrap";

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "nid-website",
};

const SingleNews = ({}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const [newsData, setNewsData] = useState(null);
    console.log("news data",newsData)
    const location = useLocation();
    // const { newsstate } = location.state;
    const { type, title } = useParams();
    const sanitizedTitle = title.replace(/-/g, " ");

    const queryParams = encodeURIComponent(sanitizedTitle);
    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_BASE_URL + `/News/NewsSection`,
                    {
                        headers: headers,
                    }
                );
                const data = await response.json();
                //console.log(data.filter(news => news.title === sanitizedTitle)[0])
                setNewsData(
                    data.filter(
                        (news) =>
                            news.title === sanitizedTitle ||
                            news.title_amh.replace(/-/g, " ") === sanitizedTitle
                    )[0]
                );
            } catch (error) {
                // Handle error
            }
        };
        const fetchEvents = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_BASE_URL + `/News/EventsNews`,
                    {
                        headers: headers,
                    }
                );
                const data = await response.json();
                setNewsData(
                    data.filter(
                        (news) =>
                            news.title.replace(/-/g, " ") === sanitizedTitle
                    )[0]
                );
            } catch (error) {
                // Handle error
            }
        };

        if (type == "Event") {
            fetchEvents();
        } else {
            fetchNews();
        }

        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     console.log(newsData);
    // }, [newsData]);

    return (
        <>
            {/* <Helmet>
        <meta
          property="og:title"
          content="This is the offical website of national id of ethiopia"
        />

        <meta property="og:description" content={newsData.title} />
      </Helmet> */}

            {newsData ? (
                <>
                    <Helmet>
                        <meta
                            property='og:title'
                            content='This is the offical website of national id of ethiopia'
                        />

                        <meta
                            property='og:description'
                            content={newsData.title}
                        />
                        <title>{`National ID | ${newsData.title}`}</title>
                    </Helmet>
                    <>
                        <HeroLandingPage news={newsData} />

                        {isDesktopOrLaptop ? (
                            <Body news={newsData} />
                        ) : (
                            <BodyNews news={newsData} />
                        )}

                        <SmallFooter />
                    </>
                </>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};
export default SingleNews;
