import React, { useEffect, useState } from "react";
import idDayLogo from "../../../../assets/ID-Day.png";
import { Link } from "react-router-dom"; 
import { useTranslation } from "react-i18next";
const TimerCountComponent = ({ openModal }) => {
    // const calculateTimeLeft = () => {
    //     const difference = +new Date("2024-09-16") - +new Date();
    //     let timeLeftIs = [];
    //     if (difference > 0) {
    //         const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    //         const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    //         const minutes = Math.floor((difference / 1000 / 60) % 60);
    //         const seconds = Math.floor((difference / 1000) % 60);

    //         timeLeftIs = [days, hours, minutes, seconds];
    //     } else {
    //         setTimeIsUp(true);
    //     }
    //     return timeLeftIs;
    // };
    // const [timeLeft, setTimeLeft] = useState([]);
    const [timeIsUp, setTimeIsUp] = useState(
        true
    );
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setTimeLeft(calculateTimeLeft());
    //     }, 1000);

    //     return () => clearTimeout(timer);
    // }, [timeLeft]);
    const { t } = useTranslation();

    return (
        <div className='flex w-full flex-col items-end justify-center gap-2 pb-10'>
            <div className='flex min-w-[350px] max-w-[650px] max-h-[250px] md:max-h-[400px] justify-center'>
                <img src={idDayLogo} className='object-scale-down' />
            </div>

            <a
                href='https://www.id-day.org'
                className='font-bold w-full max-w-[250px] mx-auto rounded-full text-lg md:text-2xl border-2 border-white px-8 md:px-14 py-1 text-white bg-[#083247]/90 mb-2 hover:bg-[#083247] hover:scale-105 duration-300 ease-in-out'
            >
                {t("learn-more")}
            </a>
            <button
                onClick={openModal}
                className='md:hidden font-bold w-full max-w-[250px] mx-auto rounded-full text-lg md:text-2xl border-2 border-white px-8 md:px-14 py-1 text-white bg-[#083247]/90 mb-2 hover:bg-[#083247] hover:scale-105 duration-300 ease-in-out'
            >
                {t("hero-register")}
            </button>

            {timeIsUp ? (
                <div className='flex justify-center gap-3 md:gap-6 w-full px-5 max-w-fit mx-auto mt-8'>
                    <div className='bg-[#FFA42A]/40 border px-4 md:px-10 py-2 md:py-6 w-full md:min-w-[100px] flex flex-col items-center shadow-md rounded-2xl justify-center md:min-h-[100px]'>
                        <p className='text-4xl'>{0}</p>
                        <p className='text-[#083247] font-semibold'>
                            {t("days")}
                        </p>
                    </div>
                    <div className='bg-[#FFA42A]/40 border px-4 md:px-10 py-2 md:py-6 w-full md:min-w-[100px] flex flex-col items-center shadow-md rounded-2xl justify-center md:min-h-[100px]'>
                        <p className='text-4xl'>{0}</p>
                        <p className='text-[#083247] font-semibold'>
                            {" "}
                            {t("hours")}
                        </p>
                    </div>
                    <div className='bg-[#FFA42A]/40 border px-4 md:px-10 py-2 md:py-6 w-full md:min-w-[100px] flex flex-col items-center shadow-md rounded-2xl justify-center md:min-h-[100px]'>
                        <p className='text-4xl'>{0}</p>
                        <p className='text-[#083247] font-semibold'>
                            {" "}
                            {t("minutes")}
                        </p>
                    </div>
                </div>
            ) : (
                <div className='grid grid-cols-3 justify-center gap-3 md:gap-6 w-full px-5 max-w-fit mx-auto mt-8'>
                    {/* <div className='bg-[#FFA42A]/40 border px-4 md:px-10 py-2 md:py-6 w-full md:min-w-[100px] flex flex-col items-center shadow-md rounded-2xl justify-center md:min-h-[100px]'>
                        <p className='text-4xl'>{timeLeft[0]}</p>
                        <p className='text-[#083247] font-semibold'>
                            {" "}
                            {t("days")}
                        </p>
                    </div>
                    <div className='bg-[#FFA42A]/40 border px-4 md:px-10 py-2 md:py-6 w-full md:min-w-[100px] flex flex-col items-center shadow-md rounded-2xl justify-center md:min-h-[100px]'>
                        <p className='text-4xl'>{timeLeft[1]}</p>
                        <p className='text-[#083247] font-semibold'>
                            {" "}
                            {t("hours")}
                        </p>
                    </div>
                    <div className='bg-[#FFA42A]/40 border px-4 md:px-10 py-2 md:py-6 w-full md:min-w-[100px] flex flex-col items-center shadow-md rounded-2xl justify-center md:min-h-[100px]'>
                        <p className='text-4xl'>{timeLeft[2]}</p>
                        <p className='text-[#083247] font-semibold'>
                            {" "}
                            {t("minutes")}
                        </p>
                    </div> */}
                </div>
            )}
        </div>
    );
};

export default TimerCountComponent;
